import React from 'react';
import styled from '@emotion/styled';

const AnchorContainer = styled.div`
  scroll-padding: 160px;
  padding-top: 160px;
  margin-top: -160px;
`;

const AnchorScreenReader = styled.div`
  display: none;
  height: 1px;
`;

const Anchor = props => (
  <AnchorContainer>
    <a
      name={'#' + props.anchor_id.replace(/\s/g, '').toLowerCase()}
      style={{
        scrollPadding: '160px',
        paddingTop: '160px',
        marginTop: '160px',
      }}
      id={props.anchor_id.replace(/\s/g, '').toLowerCase()}
    ></a>
    <AnchorScreenReader>Anchor to {props.anchor_id}</AnchorScreenReader>
  </AnchorContainer>
);

export default Anchor;
