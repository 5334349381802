/*import React from 'react';
import styled from '@emotion/styled';

import SquiggleAsset from '../images/squiggles/squiggle.svg';
import SqugglePreset1 from '../images/squiggles/preset1.svg';
import SqugglePreset2 from '../images/squiggles/preset2.svg';

const SquiggleContainer = styled.div``;

const Squiggle = props => (
  <SquiggleContainer>
    {props.preset ? (
      <SqugglePreset1
        style={{
          stroke: props.color,
          mixBlendMode: 'multiply',
        }}
      />
    ) : (
      <SquiggleAsset
        style={{
          stroke: props.color,
          mixBlendMode: 'multiply',
          strokeWidth: props.strokeWidth,
          height: props.height,
        }}
      />
    )}
  </SquiggleContainer>
);

*/

import React, { Component } from 'react';
import styled from '@emotion/styled';
import VisibilitySensor from 'react-visibility-sensor';

import SquiggleAsset from '../images/squiggles/squiggle.svg';
import SqugglePreset1 from '../images/squiggles/preset1.svg';
import SqugglePreset2 from '../images/squiggles/preset2.svg';
import HomepageSquiggle from '../images/assets/homepageHeaderSquiggle.svg';
import SvgLines from 'react-mt-svg-lines';

const SquiggleContainer = styled.div``;

export class Squiggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <SquiggleContainer>
          {this.props.homepageSquiggle &&
          this.props.homepageSquiggle === true ? (
            <SvgLines
              animate={this.state.visible ? (0, 500) : 'hide'}
              duration={1000}
              key={Math.floor(Math.random() * 9999999)}
            >
              <HomepageSquiggle
                style={{
                  stroke: this.props.color,
                  mixBlendMode: 'multiply',
                  strokeWidth: this.props.strokeWidth,
                  height: this.props.height,
                }}
              />
            </SvgLines>
          ) : (
            <React.Fragment>
              {this.props.preset ? (
                <SvgLines
                  animate={this.state.visible ? (1, 600) : 'hide'}
                  duration={1000}
                >
                  <SqugglePreset1
                    style={{
                      stroke: this.props.color,
                      mixBlendMode: 'multiply',
                    }}
                  />
                </SvgLines>
              ) : (
                <SvgLines
                  animate={this.state.visible ? (1, 600) : 'hide'}
                  duration={1000}
                >
                  <SquiggleAsset
                    style={{
                      stroke: this.props.color,
                      mixBlendMode: 'multiply',
                      strokeWidth: this.props.strokeWidth,
                      height: this.props.height,
                    }}
                  />
                </SvgLines>
              )}
            </React.Fragment>
          )}
        </SquiggleContainer>
      </VisibilitySensor>
    );
  }
}

export default Squiggle;
