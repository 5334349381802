import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { motion } from 'framer-motion';
import LazyImage from '../components/lazy-image';
import Label from '../components/Label';
import ButtonUnderlined from '../components/ButtonUnderlined';
import withUrlParams from '../utils/withUrlParams';
import PropTypes from 'prop-types';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import ExpertiseHeaderTwo from '../blocks/expertiseHeaderTwo';
import Anchor from '../blocks/anchor';
import FooterPreface from '../blocks/footerPreface';

const CaseStudyBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CaseStudyItemsContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1080px;
`;

const CaseStudyItemContainer = styled.div`
  margin: 45px;
`;

const ThumbnailContainer = styled.div`
  height: 450px;
  width: 450px;
  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: -0.03em;
  color: #fff;
  margin-top: 25px;
  margin-bottom: 25px;
`;

export class CaseStudies extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout
        logo={'crimson_black'}
        header_items_color={'#ffffff'}
        mobile_logo={'crimson_black'}
        mobile_header_items_color={'#ffffff'}
        fixed_background_color={'false'}
        fixed_background_color={'#fff'}
        fixed_menu_items_color={'#ffffff'}
        fixed_menu_logo={'crimson_black'}
        fixed_mobile_logo={'crimson_black'}
        menu_background={'true'}
        footer_logo={'crimson_black'}
      >
        <SEO title="Case Studies" />
        <ExpertiseHeaderTwo
          header_text={
            this.props.data.allPrismicCaseStudy.nodes[0].data.header_title[0]
          }
          subtitle_text={
            this.props.data.allPrismicCaseStudy.nodes[0].data.header_subtitle[0]
              .text
          }
          cta_text={
            this.props.data.allPrismicCaseStudy.nodes[0].data.cta_title[0].text
          }
          cta_link={
            this.props.data.allPrismicCaseStudy.nodes[0].data.cta_link.url
          }
          cta_text_color={'#ffffff'}
          theme_color={
            this.props.data.allPrismicCaseStudy.nodes[0].data.color_theme
          }
          left_aligned_image={
            this.props.data.allPrismicCaseStudy.nodes[0].data.header_left_image
              .url
          }
          right_aligned_image={
            this.props.data.allPrismicCaseStudy.nodes[0].data.header_right_image
              .url
          }
        />
        <Anchor anchor_id={'content'} />
        <CaseStudyBlock>
          <CaseStudyItemsContainer>
            {this.props.data.allPrismicPage.nodes.map((item, index) => (
              <React.Fragment>
                {item.data.page_type === 'case_study' ? (
                  <CaseStudyItemContainer>
                    <a href={item.url}>
                      <ThumbnailContainer>
                        {item.data.page_thumbnail && (
                          <LazyImage {...item.data.page_thumbnail} />
                        )}
                      </ThumbnailContainer>
                    </a>
                    <Title>{item.data.page_title.text}</Title>
                    <ButtonNotUnderlined
                      link={item.url}
                      buttonText={'See More'}
                      color={'#FF22EE'}
                      arrowColor={'#FF22EE'}
                    />
                  </CaseStudyItemContainer>
                ) : null}
              </React.Fragment>
            ))}
          </CaseStudyItemsContainer>
        </CaseStudyBlock>
        <FooterPreface
          subtitle_text={
            this.props.data.allPrismicCaseStudy.nodes[0].data
              .footer_preface_subtitle_text[0].text
          }
          main_text={
            this.props.data.allPrismicCaseStudy.nodes[0].data
              .footer_preface_title_text[0].text
          }
          cta_color={'#ffffff'}
          cta_text={
            this.props.data.allPrismicCaseStudy.nodes[0].data
              .footer_preface_cta_text[0].text
          }
          cta_link={
            this.props.data.allPrismicCaseStudy.nodes[0].data
              .footer_preface_cta_link.url
          }
          footer_text_background_wave={
            this.props.data.allPrismicCaseStudy.nodes[0].data.background_wave
              .url
          }
        />
      </Layout>
    );
  }
}

export default withUrlParams(CaseStudies);

export const query = graphql`
  query CaseStudyQuery {
    allPrismicPage {
      nodes {
        first_publication_date
        url
        data {
          page_type
          page_title {
            text
          }
          page_thumbnail {
            url
          }
        }
      }
    }
    allPrismicCaseStudy {
      nodes {
        data {
          color_theme
          header_title {
            text
          }
          header_subtitle {
            text
          }
          cta_title {
            text
          }
          cta_link {
            url
          }
          header_left_image {
            url
          }
          background_wave {
            url
          }
          header_right_image {
            url
          }
          footer_preface_subtitle_text {
            text
          }
          footer_preface_title_text {
            text
          }
          footer_preface_cta_text {
            text
          }
          footer_preface_cta_link {
            url
          }
        }
      }
    }
  }
`;
